import React from "react"
import { Route, Routes } from "react-router-dom"
import { LocalesProvider } from "../../../packages/localization/client-side/useLocalesContext"
import { ModalProvider } from "../../../packages/modal/Modal"
import { Studio } from "../../../studio/Studio"
import { AccountPage } from "./AccountPage"
import { useMenu, useUserPrivileges } from "./client"
import { ContactMe } from "./ContactMe"
import { FundPage } from "./FundPage"
import { ArticlePage } from "./ArticlePage"
import { PageView } from "./Page"
import { OfferingReportView } from "./OfferingReportView"
import "./sections/AccountInfo"
import "./sections/EmissionsForecast"
import "./sections/EmissionsReporting"
import "./sections/Footer"
import "./sections/Header"
import "./sections/ImageAndTextGrid"
import "./sections/ImageAndText"
import "./sections/TwoImages"
import "../../../packages/editing/sections/Placeholder"
import "../../../packages/editing/sections/HorizontalStack"
import "./sections/RelatedFrameworks"
import "./sections/Solutions"
import "./sections/Verticals"
import "./sections/Tabs"
import "./sections/HeadingAndText"
import "./sections/DoughnutChart"
import "./sections/BarChart"
import { MenuContext } from "./MenuContext"
import { FrameworkReport } from "./FrameworkReport"
import { FormReport } from "./FormReport"
import { EditableSiteLazy } from "../../../packages/editing/EditableSiteLazy"
import { Lazy } from "../../../packages/code-splitting/Lazy"
import "../../../packages/widgets/AllWidgets"
import { Login } from "../../../studio/Login"
import { CookieConsentView } from "../../../packages/cookie-consent/CookieConsentView"
import { useAnalyticsLogger } from "../../../packages/analytics/useAnalyticsLogger"

export function App() {
    useAnalyticsLogger()
    const { data: privileges } = useUserPrivileges()
    const { data: menu } = useMenu()
    if (!menu) return <></>

    return (
        <MenuContext.Provider value={menu}>
            <ModalProvider>
                <LocalesProvider>
                    <Routes>
                        {Studio()}
                        <Route
                            path="/"
                            element={
                                <>
                                    <EditableSiteLazy condition={!!privileges?.canEditWebsite} />
                                    <CookieConsentView />
                                </>
                            }>
                            <Route path="/login" element={<Login />} />
                            <Route index element={<PageView />} />
                            <Route path="/articles/:slug" element={<ArticlePage />} />
                            <Route path="/accounts/:slug" element={<AccountPage />} />
                            <Route
                                path="/reports/offerings/:offeringId"
                                element={<OfferingReportView />}
                            />
                            <Route
                                path="/reports/accounts/:accountId/frameworks/:frameworkId"
                                element={<FrameworkReport />}
                            />
                            <Route path="/reports/forms/:formId" element={<FormReport />} />
                            <Route
                                path="/kycaml/:orgId"
                                element={
                                    <Lazy
                                        target={async () =>
                                            (await import("./KYCAML")).KYCAMLFormView
                                        }
                                    />
                                }
                            />
                            <Route path="/funds/:slug" element={<FundPage />} />
                            <Route path="/contact-me" element={<ContactMe />} />
                            <Route path="/:slug" element={<PageView />} />
                            {/* Deprecated, use /accounts/:slug*/}
                            <Route path="/solutions/:slug" element={<AccountPage />} />
                        </Route>
                    </Routes>
                </LocalesProvider>
            </ModalProvider>
        </MenuContext.Provider>
    )
}
