import { useEffect, useState } from "react"
import { BigCalendar, BigCalendarEvent } from "./BigCalendar"
import { RegisterWidget } from "./WidgetView"

import "moment/locale/nb"
import "moment/locale/sv"
import moment from "moment"
import { useCurrentLocale } from "../localization/client-side/useLocalize"
import { LocaleKey } from "../localization/Locale"
import { ColorStyles } from "../ui"
import { View } from "react-big-calendar"
import { Icon } from "../../studio/Views/Icon"
import { useWidgetState } from "./WidgetContext"
import { YearMonth } from "../../reactor/Types/Primitives/YearMonthDate"
import { useNavigate } from "../hooks/useNavigate"

RegisterWidget("Big Calendar", BigCalendarView)

/** Displays a asynchronously fetched widget. */
export function BigCalendarView({ value }: { value: BigCalendar }) {
    const [reactBigCalendar, setReactBigCalendar] = useState<
        typeof import("react-big-calendar") | undefined
    >()
    const [localizer, setLocalizer] = useState<import("react-big-calendar").DateLocalizer>()
    const locale = useCurrentLocale()
    const [view, setView] = useState<View>("month")
    const navigate = useNavigate()

    const [currentMonth, setCurrentMonth] = useWidgetState(
        value.widgetKey,
        "currentMonth",
        YearMonth.thisMonth()
    )

    useEffect(() => {
        // When the component re-mounts, reset the current month to the current
        // month, otherwise the persisted widget state and the calendar will be
        // out of sync.
        void setCurrentMonth(YearMonth.thisMonth())
    }, [])

    useEffect(() => {
        if (!reactBigCalendar) {
            void import("react-big-calendar/lib/css/react-big-calendar.css")
            void import("react-big-calendar").then((bigCalendar) => {
                setReactBigCalendar(bigCalendar)
                const momentLocalizer = bigCalendar.momentLocalizer(moment)
                setLocalizer(momentLocalizer)
            })
            // Inject the CSS for the calendar
            const style = document.createElement("style")
            style.innerHTML = `.rbc-time-slot {
                height: 160px !important;
              }`
            document.head.appendChild(style)
        }
    }, [])

    if (!reactBigCalendar || !localizer) return <div>Loading...</div>

    const { Calendar } = reactBigCalendar

    let culture = "en"
    let messages = englishMessages

    if (locale === LocaleKey.no) {
        culture = "nb"
        moment.locale("nb")
        messages = norwegianMessages
    } else if (locale === LocaleKey.sv) {
        culture = "sv"
        moment.locale("sv")
        messages = swedishMessages
    } else {
        moment.locale("en")
    }

    const startOfDay = new Date()
    startOfDay.setHours(value.startOfDay ?? 6, 0, 0, 0)

    const endOfDay = new Date()
    endOfDay.setHours(23, 0, 0, 0)

    const title = (e: BigCalendarEvent) => (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textDecoration: e.inactive ? "line-through" : "none",
                fontSize: 14,
            }}>
            {(e.style === "bullet" || e.icon) &&
                (e.icon ? (
                    <Icon
                        icon={e.icon}
                        size={16}
                        style={{
                            marginRight: 8,
                            marginTop: 1,
                            marginLeft: 2,
                            color:
                                e.style === undefined || e.style === "filled"
                                    ? "white"
                                    : ColorStyles[e.color][600],
                        }}
                    />
                ) : (
                    <div
                        style={{
                            backgroundColor: ColorStyles[e.color][600],
                            width: 8,
                            height: 8,
                            borderRadius: 4,
                            marginRight: 8,
                        }}></div>
                ))}
            <b>{e.title}</b>
        </div>
    )

    return (
        <Calendar
            localizer={localizer}
            view={view}
            onView={(newView) => setView(newView)}
            onNavigate={(date) => {
                const yearMonth = YearMonth(date.getFullYear(), date.getMonth() + 1)
                // Avoid redundant state updates, as this will trigger a new
                // call to the backend. If the calendar contains a lot of
                // events, this can be slow, so only do it on actual month changes.
                if (yearMonth !== currentMonth) void setCurrentMonth(yearMonth)
            }}
            events={value.events.map((e) => {
                const isAllDay = e.start && !e.end
                const eventAllDayStart = isAllDay
                    ? new Date(e.start!.valueOf()).setHours(0, 0, 0, 0)
                    : undefined

                const eventAllDayEnd = isAllDay
                    ? new Date(e.start!.valueOf()).setHours(23, 59, 59, 999)
                    : undefined

                return {
                    ...e,
                    title: (
                        <a
                            href={e.link}
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontStyle: "normal",
                            }}>
                            {title(e)}
                            {view !== "month" && e.description && <div>{e.description}</div>}
                        </a>
                    ),
                    allDay: isAllDay,
                    start: eventAllDayStart ?? (e.start ? new Date(e.start.valueOf()) : undefined),
                    end: eventAllDayEnd ?? (e.end ? new Date(e.end?.valueOf()) : undefined),
                }
            })}
            onSelectEvent={(event, e) => {
                if (event.link) {
                    navigate(event.link)
                    e.stopPropagation()
                    e.preventDefault()
                }
            }}
            min={value.startOfDay && (view === "week" || view === "day") ? startOfDay : undefined}
            max={value.endOfDay && (view === "week" || view === "day") ? endOfDay : undefined}
            timeslots={view === "month" ? 3 : 1}
            step={60}
            startAccessor="start"
            endAccessor="end"
            components={{
                timeSlotWrapper: (props) => {
                    return <div style={{ minHeight: 148 }} {...props} />
                },
            }}
            eventPropGetter={(event) => ({
                style: {
                    color:
                        event.style === "outline"
                            ? ColorStyles[event.color][800]
                            : event.style === "bullet"
                              ? ColorStyles.gray[600]
                              : "white",

                    border:
                        event.style === "outline"
                            ? `1px solid ${ColorStyles[event.color][800]}`
                            : event.style === "bullet"
                              ? `1px solid ${ColorStyles.gray[300]}`
                              : "none",

                    borderRadius: 4,
                    opacity: event.inactive ? 0.5 : 1,
                    backgroundColor:
                        event.style === "bullet" || event.style === "outline"
                            ? "white"
                            : ColorStyles[event.color][600],
                },
            })}
            culture={culture}
            messages={messages}
            className="reactor-studio"
            style={{
                height: 600,
                marginLeft: 12,
                marginTop: 16,
            }}
        />
    )
}

const englishMessages = {
    allDay: "All day",
    previous: "Previous",
    next: "Next",
    today: "Today",
    month: "Month",
    week: "Week",
    day: "Day",
    agenda: "Agenda",
    date: "Date",
    time: "Time",
    event: "Event",
    showMore: (count: number) => `+${count} more`,
}
const norwegianMessages = {
    allDay: "Hele dagen",
    previous: "Forrige",
    next: "Neste",
    today: "I dag",
    month: "Måned",
    week: "Uke",
    day: "Dag",
    agenda: "Agenda",
    date: "Dato",
    time: "Tid",
    event: "Hendelse",
    showMore: (count: number) => `+${count} flere`,
}
const swedishMessages = {
    allDay: "Hela dagen",
    previous: "Föregående",
    next: "Nästa",
    today: "Idag",
    month: "Månad",
    week: "Vecka",
    day: "Dag",
    agenda: "Agenda",
    date: "Datum",
    time: "Tid",
    event: "Händelse",
    showMore: (count: number) => `+${count} fler`,
}
